@font-face {
  font-family: 'CanoGroup';
  src: local('CanoGroup'), url('assets/fonts/canogroup/canogroup-100.ttf') format('truetype');
}
@font-face {
  font-family: 'Promoove';
  src: url('assets/fonts/canogroup/canogroup-100.ttf') format('truetype');
}
@font-face {
  font-family: 'Promoove Medium';
  src: url('assets/fonts/canogroup/canogroup-100.ttf') format('truetype');
  font-style: 'normal';
}
@font-face {
  font-family: 'Promoove Light';
  src: url('assets/fonts/canogroup/canogroup-100.ttf') format('truetype');
  font-style: 'normal';
}
/* @font-face {
  font-family: 'Promoove';
  src: url('assets/fonts/promoove/Roboto-Bold.ttf') format('truetype');
  font-weight: 'bold';
} */
/* @font-face {
  font-family: 'Promoove';
  src: url('assets/fonts/promoove/Roboto-Italic.ttf') format('truetype');
  font-style: 'italic';
} */

/* @font-face {
  font-family: 'Promoove';
  src:  url('assets/fonts/promoove/Roboto-BoldItalic.ttf') format('truetype');
  font-style: 'italic';
  font-weight: 'bold';
} */
